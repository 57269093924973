<template>
  <div>
    <HomeFirstArticleComponent />

    <ServicesComponent />
    <PackagesComponent />

    <section>
      <article class="country_section">
        <div class="container">
          <h2 class="mb-5 text-center">Top Rated East African Safari Countries</h2>
          <div
            class="country_tours_grid"
            v-if="isLoading || countries.length == 0"
          >
            <div
              v-for="i in 4"
              :key="i"
              class="skeleton-block skeleton-effect-wave"
            ></div>
          </div>
          <div
            v-else
            class="country_tours_grid"
          >
            <div
              v-for="country in countries"
              :key="country.id"
              @click="$router.push({ path: '/safari-packages', query: { whereTo: `${country.country_title}`} })"
            >
              <img :src="country.featured_photo">
              <div
                class="text-center"
                @click="$router.push({ path: '/safari-packages', query: { whereTo: `${country.country_title}`} })"
              >
                <h3>{{ country.country_title }} Tours</h3>
                <div class="country_text mt-2">
                  <span>Great wildlife in both forest and savannah.</span><br />
                  <span style="color: #D96B12; font-weight: 600;">Explore More <i class="el-icon-top-right"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article>
        <div class="container access_video">
          <iframe
            src="https://www.youtube.com/embed/Zx0ku3nHrvA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </article>
    </section>

    <section>
      <article class="japan_expo_section">
        <div class="container">
          <h2 class="mb-5">Access Uganda in the Tourism Expo Japan</h2>
          <div class="row">
            <div class="col-md-6 pb-5">
              <div class="japan_expo_grid">
                <div>
                  <img
                    src="../assets/images/japan/jap1.jpg"
                    alt=""
                  >
                </div>
                <div>
                  <img
                    src="../assets/images/japan/jap2.jpg"
                    alt=""
                  >
                </div>
                <div>
                  <img
                    src="../assets/images/japan/jap3.jpg"
                    alt=""
                  >
                </div>
                <div>
                  <img
                    src="../assets/images/japan/jap4.jpg"
                    alt=""
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <p>
                Mr. Hassan Mutebi represented Uganda at the 2022 Japan Tourism Expo as part of the Uganda Tourism Board and Access Uganda Tours LTD. This was an exciting opportunity for Mr. Mutebi to showcase the natural beauty and diverse attractions of Uganda to the Japanese market.
              </p>
              <p>
                At the expo, Mr. Mutebi had the opportunity to network with other tourism industry professionals and learn about the latest trends and developments in the field. He also had the chance to promote Uganda as a top travel destination to Japanese tourists.
              </p>
              <p>
                Mr. Mutebi highlighted the many unique and exciting experiences that Uganda has to offer, such as gorilla trekking in Bwindi Impenetrable Forest, white water rafting on the Nile River, and game viewing in national parks like Queen Elizabeth and Murchison Falls. He also discussed the country's rich cultural heritage and the warmth and hospitality of the Ugandan people.
              </p>
              <p>
                Overall, the expo was a success for Mr. Mutebi and Uganda. He was able to promote Uganda as a top destination for Japanese tourists and establish valuable connections in the industry. As a result of his efforts, it is expected that Uganda will see an increase in Japanese tourists in the coming years.
              </p>
              <a
                href="https://www2.t-expo.jp/exhibitors/view/en/43739/BtoC"
                target="_blank"
              >
                <button class="btn btn-md moreBtn">
                  Explore more
                  <i class="el-icon-right"></i>
                </button>
              </a>

            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article>
        <div
          class="container"
          style="padding-top: 100px; padding-bottom: 100px;"
        >
          <div class="why_lugave">
            <div style="display: flex; align-items: center; justify-content: center;">
              <div class="text-center">
                <img
                  class="brand-logo"
                  alt="Access Safaris logo"
                  src="https://static.wixstatic.com/media/9cadc7_dbaca97aea8840ad86acf76c5e82415c.jpg/v1/fill/w_267,h_261,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/9cadc7_dbaca97aea8840ad86acf76c5e82415c.jpg"
                  width="100%"
                >
              </div>
            </div>
            <div>
              <div>
                <h2>Why Access Uganda Safaris?</h2>
                <ul class="check-list">
                  <li>Tailor made routings</li>
                  <li>Experienced travel specialists on the ground</li>
                  <li>Professional and knowledgable guides</li>
                  <li>Certified Sustainable Tour Operator</li>
                  <li>24/7 Emergency line</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="affiliates_section">
        <div class="container text-center">
          <h2
            class="mb-5"
            data-aos="fade-up"
            data-aos-duration="2000"
          >Our Affiliates</h2>
          <div
            class="row"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="affiliates_grid">
                <div>
                  <img
                    src="../assets/images/group_1_2da16e4c.webp"
                    alt=""
                    width="100%"
                  >
                </div>
                <div>
                  <img
                    src="../assets/images/group_1_3d16ea69.webp"
                    alt=""
                    width="100%"
                  >
                </div>
                <div>
                  <img
                    src="../assets/images/group_1_4332315c.webp"
                    alt=""
                    width="100%"
                  >
                </div>
                <div>
                  <img
                    src="../assets/images/group_1_57c3a836.webp"
                    alt=""
                    width="100%"
                  >
                </div>
                <div>
                  <img
                    src="../assets/images/group_1_6d305674.webp"
                    alt=""
                    width="100%"
                  >
                </div>
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </article>
    </section>

  </div>
</template>

<script>
import HomeFirstArticleComponent from "../components/home/home-first-article-component.vue";
import ServicesComponent from "../components/home/services-component.vue";
import PackagesComponent from "../components/home/packages-component.vue";

export default {
  components: {
    // CarouselComponent,
    PackagesComponent,
    ServicesComponent,
    HomeFirstArticleComponent,
  },

  data() {
    return {
      isLoading: false,
      countries: [],
    };
  },

  mounted() {
    this.getCountries();
  },

  methods: {
    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },

    async getCountries() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/destination/country/get_all`
        );
        if (request.data.success) {
          this.countries = request.data.countries;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        return;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.country_section {
  background-color: #f0f9eb;
  padding-top: 100px;
  padding-bottom: 100px;
}

h2 {
  font-size: 2.5em;
  font-weight: 300;
}
.access_video{
  padding-top: 100px;
  padding-bottom: 100px;
}

.access_video iframe {
  width: 100%;
  height: 500px;
}
.why_lugave {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.why_lugave > div:first-child {
  /* background-color: #5c4b13; */
}
.why_lugave > div:nth-child(2) {
  padding: 20px 20px 20px 50px;
  background-color: white;
  display: flex;
  align-items: center;
}

.tent_adv img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.check-list {
  margin-top: 15px;
  padding-left: 1.2rem;
  padding: 0px;
}
.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 2.5rem;
  margin-bottom: 0.5rem;
}

.check-list li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -2px;
  width: 5px;
  height: 11px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: #00a8a8;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.country_tours_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.country_tours_grid > div {
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  height: 450px;
}

.country_tours_grid > div img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 5px;
}

.country_tours_grid > div > div {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px 10px 50px 10px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  color: white;
  border-radius: 0px 0px 5px 5px;
}

h3 {
  font-size: 1.8em;
  font-weight: 500;
}

.country_tours_grid .country_text {
  font-size: 1em;
  font-weight: 300;
}

.japan_expo_section {
  background-color: #eee;
  padding-top: 100px;
  padding-bottom: 100px;
}

.japan_expo_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.japan_expo_grid > div {
  height: 100%;
}

.japan_expo_grid > div {
}

.japan_expo_grid img {
  width: 100%;
}

.moreBtn {
  background-color: red;
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.moreBtn:hover {
  background-color: rgba(61, 41, 10, 0.5);
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.affiliates_section {
  background-color: #eee;
  padding-top: 100px;
  padding-bottom: 100px;
}

.affiliates_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {

  h2 {
    font-size: 2.0em;
  }
  .country_tours_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .country_tours_grid > div {
    height: 250px;
  }
  .country_tours_grid > div img {
    height: 250px;
  }

  h3 {
    font-size: 1.2em;
    font-weight: 600;
  }

  .country_tours_grid .country_text {
    font-size: 0.8em;
    font-weight: 300;
  }

  .country_tours_grid > div > div {
    padding: 10px 10px 30px 10px;
  }
  .access_video iframe {
    width: 100%;
    height: 300px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {

  h2 {
    font-size: 2.0em;
  }
  .country_tours_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .country_tours_grid > div {
    height: 250px;
  }
  .country_tours_grid > div img {
    height: 250px;
  }

  h3 {
    font-size: 1.2em;
    font-weight: 600;
  }

  .country_tours_grid .country_text {
    font-size: 0.8em;
    font-weight: 300;
  }

  .country_tours_grid > div > div {
    padding: 10px 10px 30px 10px;
  }
  .why_lugave {
    display: grid;
    grid-template-columns: 1fr;
  }
  .why_lugave > div:first-child {
    /* background-color: #5c4b13; */
  }
  .why_lugave > div:nth-child(2) {
    padding: 50px 20px 20px 50px;
    background-color: white;
    display: flex;
    align-items: center;
  }
  .access_video iframe {
    width: 100%;
    height: 300px;
  }
}
</style>
