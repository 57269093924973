<template>
  <article>
    <section>
      <div class="packages-section">
        <div class="container">
          <h2 class="mb-5 text-center">
            Popular Safari Tours & Holidays
          </h2>
          <div
            v-if="isLoading || safari_tours.length == 0"
            class="px-0 mt-3 col-12"
          >
            <div class="products_skeletons">
              <div
                v-for="i in 6"
                :key="i"
                class="skeleton-block skeleton-effect-wave"
              ></div>
            </div>
          </div>
          <div v-else>
            <section>
              <div
                class="tour_package_grid"
                data-aos="zoom-in-down"
                data-aos-duration="3000"
              >
                <div
                  class="tour_package_item"
                  v-for="tour of safari_tours"
                  :key="tour.id"
                >
                  <div
                    style="cursor: pointer;"
                    @click="$router.push({ path: `/package-detail/${tour.title}/${tour.id}` })"
                  >
                    <img :src="tour.image">
                    <div>
                      <a>
                        <div class="text-white tour_title">
                          <span>{{tour.title}}</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="package_brief_detail">
                    <div class="package_description">
                      <!-- <span class="package_amount">
                        <strong>${{tour.amount}} pp (USD).</strong>
                      </span><br> -->
                      <span>
                        <small
                          class="desc_desktop"
                          :inner-html.prop="tour.brief_description | truncate(180, '...')"
                        >
                          <!-- {{ tour.brief_description | truncate(180, '...')   }} -->
                        </small>
                        <small
                          class="desc_mobile"
                          :inner-html.prop="tour.brief_description | truncate(80, '...')"
                        >
                          <!-- {{ tour.brief_description | truncate(80, '...')   }} -->
                        </small>
                      </span><br />
                      <span>
                        <i class="el-icon-location-outline"></i>
                        {{ tour.destination_country }}
                      </span>
                    </div>
                    <div
                      class="package_detail_link"
                      @click="$router.push({ path: `/package-detail/${tour.title}/${tour.id}` })"
                    >
                      <span>Explore This Tour <i class="el-icon-d-arrow-right"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="
                      text-center
                      mt-5">
                <el-button
                  style="background-color: #D96B12; color: white;"
                  @click="$router.push({ path: '/safari-packages' })"
                >Explore More East African Safaris <i class="el-icon-d-arrow-right"></i></el-button>
              </div>
            </section>
          </div>

        </div>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      loading: false,
      loadingError: false,

      safari_tours: [],
      safariPackage: {},
    };
  },

  mounted() {
    this.getSafariPackages();
  },

  methods: {
    async getSafariPackages() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/package/get_all/featured`
        );
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.safari_tours = request.data.featured_safari_tours;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.packages-section {
  padding-top: 100px; padding-bottom: 100px; background-color: white;
}
h2 {
  font-size: 2.5em;
  font-weight: 300;
}
.tour_title {
  font-size: 1.25em;
  font-weight: 600;
  text-transform: capitalize;
}
.package_amount {
  font-size: 1.2em;
}
.desc_desktop {
  display: block;
}
.desc_mobile {
  display: none;
}
.tour_package_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.tour_package_grid > div {
  background-color: #f5f7f8;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;
        transition: 1s;
}
.tour_package_grid > div:hover {
  -webkit-box-shadow: 10px 10px 16px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 16px -10px rgba(0, 0, 0, 0.75);

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transition: 1s;
}
.tour_package_grid > div > div:first-child {
  position: relative;
  width: 100%;
}
.tour_package_grid > div > div:first-child > div {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  display: flex;
  align-items: flex-end;
}
.tour_package_grid > div > div:first-child > div a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 10px 20px;
  text-decoration: none;
}
.tour_package_grid > div > div:nth-child(2) {
  transition: all 2s ease;
  border-radius: 0px 0px 0px 0px;
}
.tour_package_grid .tour_package_item img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all 2s ease;
}
.tour_package_item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;
  height: 100%;
  /* position: relative;
  transition: 1s; */
}

.package_brief_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;
  height: 100%;
}
.package_brief_detail > div {
  width: 100%;
}
.package_description {
  padding: 20px 20px 20px 20px;
}
.package_detail_link {
  padding: 20px 20px 20px 20px;
  border-top: 1px solid #eeeeee;
  background-color: #f0f9eb;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 300;
}
.products_skeletons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.products_skeletons > div {
  height: 200px;
  border-radius: 5px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  h2 {
    font-size: 2.0em;
  }
  .tour_title {
    font-size: 1.2em;
  }
  .tour_package_grid > div > div:first-child > div a {
    padding: 10px 10px;
  }
  .package_amount {
    font-size: 1em;
  }

  .desc_desktop {
    display: none;
  }
  .desc_mobile {
    display: block;
  }
  .products_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .tour_package_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .package_description {
    padding: 10px;
  }
  .package_detail_link {
    padding: 10px;
    font-size: 0.7em;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  h2 {
    font-size: 2.0em;
  }
  .tour_title {
    font-size: 1em;
  }
  .tour_package_grid > div > div:first-child > div a {
    padding: 10px 10px;
  }
  .package_amount {
    font-size: 1em;
  }

  .desc_desktop {
    display: none;
  }
  .desc_mobile {
    display: block;
  }
  .products_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .tour_package_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .package_description {
    padding: 10px;
  }
  .package_detail_link {
    padding: 10px;
    font-size: 0.7em;
  }
}
</style>