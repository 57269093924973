<template>
    <div class="kenburns">
      <img v-for="(image, index) in images" :key="index" :src="image.src" :class="{ active: index === currentIndex }" />
    </div>
  </template>
  
  <script>
  export default {
    props: {
      images: {
        type: Array,
        required: true,
      },
      interval: {
        type: Number,
        default: 5000,
      },
    },
    data() {
      return {
        currentIndex: 0,
      };
    },
    mounted() {
      this.startAnimation();
    },
    methods: {
      startAnimation() {
        setInterval(() => {
          this.currentIndex = (this.currentIndex + 1) % this.images.length;
        }, this.interval);
      },
    },
  };
  </script>
  
  <style scoped>
  .kenburns {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .kenburns img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    /* transition: opacity 2s; */

    transform: translateY(0%);
  transition: opacity 2s, transform 8s ease, filter 2s;
  }
  
  .kenburns img.active {
    opacity: 1;
    transform: scale(1.7);


  /* transform: translateY(-20%);
  filter: blur(0px); */
  }
  </style>
  