<template>
    <div>
      <section>
        <article class="services_article">
            <div class="container">
                <div class="services-heading text-center">
                    <h2>Specialized Services, Your Satisfaction</h2>
                    <p>Experience our exceptional services today and discover a new level of satisfaction tailored to your unique needs!</p>
                </div>
                <div class="services-grid">
                    <div v-for="service in ourServices" :key="service.id">
                        <img :src="service.featured_photo">
                        <div class="text-center">
                            <p>{{ service.service_title }} Tours</p>
                        </div>
                    </div>
                </div>
            </div>
        </article>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        ourServices : [ 
            { 
                "id": "1", 
                "service_title": "Air Ticketing", 
                "featured_photo": "https://images.pexels.com/photos/19745695/pexels-photo-19745695/free-photo-of-plane-flying-in-the-sky.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", 
            },
            { 
                "id": "2", 
                "service_title": "Visas Dubai, Qatar, China etc", 
                "featured_photo": "https://images.pexels.com/photos/7235902/pexels-photo-7235902.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", 
            },
            { 
                "id": "3", 
                "service_title": "Passport Application Services", 
                "featured_photo": "https://images.pexels.com/photos/4353813/pexels-photo-4353813.jpeg?auto=compress&cs=tinysrgb&w=600", 
            },
            { 
                "id": "4", 
                "service_title": "Out bound tours to Dubai and Qatar", 
                "featured_photo": "https://images.pexels.com/photos/16430571/pexels-photo-16430571/free-photo-of-burj-khalifa-at-night.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", 
            },
            { 
                "id": "5", 
                "service_title": "Hotel bookings", 
                "featured_photo": "https://images.pexels.com/photos/4099354/pexels-photo-4099354.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", 
            },
            { 
                "id": "6", 
                "service_title": "Car hires", 
                "featured_photo": "https://images.pexels.com/photos/19283207/pexels-photo-19283207/free-photo-of-an-opel-vectra-parked-near-autumnal-trees-in-a-park.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", 
            },
            { 
                "id": "7", 
                "service_title": "Gorilla Safaris in Uganda", 
                "featured_photo": "https://images.pexels.com/photos/17909076/pexels-photo-17909076/free-photo-of-gorilla-sitting-by-a-tree.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", 
            },
            { 
                "id": "8", 
                "service_title": "Birding Safaris in East Africa", 
                "featured_photo": "https://images.pexels.com/photos/16908527/pexels-photo-16908527/free-photo-of-close-up-of-a-bird-in-flight.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", 
            },
        ]
    };
    },
  
    mounted() {
        // 
    },
  
    methods: {
        
    },
  };
  </script>
  
  <style scoped>

    .services-heading h2 {
        font-size: 2.5em;
        font-weight: 600;
    } 

    .services-heading p {
        font-size: 1.2em;
        font-weight: 300;
    } 
    .services_article {
        background-color: #EBEBFA;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .services-grid {
        padding-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
    }

    .services-grid > div {
        position: relative;
        cursor: pointer;
        border-radius: 5px;
        height: 300px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        transition: 1s;
    }
        
    .services-grid > div:hover {
        -webkit-box-shadow: 10px 10px 16px -10px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 16px -10px rgba(0, 0, 0, 0.75);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transition: 1s;
    }

    .services-grid > div img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
    }

    .services-grid > div > div {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
    padding: 5px;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    color: white;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    }

    .services-grid p {
        font-size: 1.2em;
        font-weight: 500;
    }

        
    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) {
    }

    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    }

    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {
    
    }

    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
        .services-heading {
            width: 90%;
            margin: auto;
        }
        .services-heading h2 {
            font-size: 2.0em;
        } 

        .services-heading p {
            font-size: 1em;
        } 

        .services-grid {
            padding-top: 50px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
        }

        .services-grid > div {
            position: relative;
            cursor: pointer;
            border-radius: 5px;
            height: 200px;
        }

        .services-grid > div img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 5px;
        }

        .services-grid p {
            font-size: 1em;
        }
    }
  </style>