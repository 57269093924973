<template>
  <div>
    <section>
      <article class="home_article_one">
        <KenBurnsComponent
              ref="parallaxImage" :images="images" class="parallax-image"
              :style="{ transform: `translateY(${parallax}px)` }" 
        />


        <div class="home_article_one_description">
          <div
            class="row"
            style="width: 100%"
          >
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="text-center home_article_one_heading">
                <h1>The Safari Specialists</h1>
                <p>Discover wildlife and nature on a once in a lifetime safari tour</p>
              </div>
              <div class="article_form">
                <el-form
                  :model="safariPackageForm"
                  ref="safariPackageForm"
                  class="demo-safariPackageForm2"
                >
                  <div class="introductory_form">
                    <div>
                      <div>
                        <el-form-item
                          label="Where to?"
                          style="width: 100%;"
                        >
                          <el-select
                            v-model="safariPackageForm.destination_country"
                            placeholder="Country of Destination"
                            style="width: 100%"
                          >

                            <el-option
                              v-for="country in countries"
                              :key="country.id"
                              :label="country.country_title"
                              :value="country.country_title"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div>
                        <el-form-item
                          label="Start Date"
                          style="width: 100%;"
                        >
                          <el-date-picker
                            v-model="safariPackageForm.check_in_date"
                            type="date"
                            @change="changedValue()"
                            placeholder="Choose date"
                            style="width: 100%"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </div>
                    </div>
                    <div>
                      <el-form-item class="text-center w-100">
                        <el-button
                          style="width: 100%; background-color: #D96B12; color: white;"
                          @click="$router.push({ path: '/safari-packages', query: { whereTo: `${safariPackageForm.destination_country}`, startDate: `${safariPackageForm.check_in_date}` } })"
                        >Show Tours <i class="el-icon-d-arrow-right"></i></el-button>
                      </el-form-item>
                    </div>
                  </div>

                </el-form>
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import KenBurnsComponent from "./KenBurnsComponent.vue";
export default {
  components: {
    KenBurnsComponent
  },

  data() {
    return {
      isLoading: false,
      countries: [],

      safariPackageForm: {
        title: "",
        check_in_date: "",
        number_adult_guest: "",
        number_children_guest: "",
      },

        parallax: 0,
        images:  [
          { src: 'https://images.pexels.com/photos/7190227/pexels-photo-7190227.jpeg' },
          { src: 'https://images.pexels.com/photos/3787839/pexels-photo-3787839.jpeg' },
          { src: 'https://images.pexels.com/photos/19599908/pexels-photo-19599908/free-photo-of-small-bird-on-branch.jpeg' },
          { src: 'https://images.pexels.com/photos/4781951/pexels-photo-4781951.jpeg' },
        ],
    };
  },

  mounted() {
    this.getDestinations();
    window.addEventListener("scroll", this.handleScroll);
  },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },

  methods: {

      blurBackground() {
        document.body.classList.add('blur');
      },
      handleScroll() {
        this.parallax = window.pageYOffset * 0.7;
      },

    async getDestinations() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/destination/country/get_all`
        );
        if (request.data.success) {
          this.countries = request.data.countries;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        return;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.home_article_one {
  position: relative;
  height: 92vh;
  overflow: hidden;
}
.home_article_one_description {
  position: absolute;
  top: 0px;
  bottom: 50px;
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.home_article_one_description h1 {
  color: white;
  font-size: 3em;
  font-weight: 600;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}
.home_article_one_description p {
  color: white;
  font-size: 1.5em;
  font-weight: 300;
}
.article_form {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 10px 10px 20px 10px;
}
.introductory_form {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2px;
}
.introductory_form > div {
  display: flex;
  align-items: flex-end;
}

.introductory_form > div:first-child {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
}
/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .home_article_one {
    height: 90vh;
  }
  .home_article_one_description h1 {
    font-size: 2.2em;
  }
  .home_article_one_description p {
    font-size: 1.2em;
  }

  .introductory_form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .home_article_one {
    height: 90vh;
  }
  .home_article_one_description h1 {
    font-size: 2.2em;
  }
  .home_article_one_description p {
    font-size: 1.2em;
  }

  .introductory_form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
  }
}
</style>
<style>
.article_form .el-form-item {
  margin-bottom: 0px;
}

.article_form .el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
  color: white !important;
}
.article_form label {
  display: inline-block;
  margin-bottom: 0px;
  color: white !important;
}
</style>